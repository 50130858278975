@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@syncfusion/ej2-base/styles/tailwind.css";
@import "@syncfusion/ej2-icons/styles/tailwind.css";
@import "@syncfusion/ej2-buttons/styles/tailwind.css";
@import "@syncfusion/ej2-inputs/styles/tailwind.css";
@import "@syncfusion/ej2-dropdowns/styles/tailwind.css";
@import "@syncfusion/ej2-lists/styles/tailwind.css";
@import "@syncfusion/ej2-navigations/styles/tailwind.css";
@import "@syncfusion/ej2-splitbuttons/styles/tailwind.css";
@import "@syncfusion/ej2-grids/styles/tailwind.css";
@import "@syncfusion/ej2-popups/styles/tailwind.css";
@import "@syncfusion/ej2-calendars/styles/tailwind.css";
@import "@syncfusion/ej2-react-schedule/styles/tailwind.css";
@import "@syncfusion/ej2-react-filemanager/styles/tailwind.css";
@import "@syncfusion/ej2-layouts/styles/tailwind.css";

@layer components {
    .input-registered, .input-registered-required { @apply flex-1 block w-full focus:ring-0 focus:border-divider min-w-0 rounded-sm sm:text-sm border-default bg-secondary text-secondary; }
    .input-registered-invalid { @apply flex-1 block w-full focus:ring-red-500 focus:border-red-500 min-w-0 rounded-sm sm:text-sm border-red-300 text-red-900 placeholder-red-300 bg-secondary; }

    /** General CSS **/
    .crmls-link { @apply text-header hover:text-opacity-50; }
    .basic-link { @apply inline-block text-sm text-header align-baseline hover:text-opacity-80 cursor-pointer; }
    .basic-form-field, .basic-form-field-listbox button, .basic-form-field-typeahead .input-registered { @apply w-full block px-4 py-2 font-normal bg-secondary text-secondary border border-solid rounded shadow-sm transition ease-in-out border-divider focus:border-divider; }
    .cta-button { @apply py-4 px-4 sm:px-6 xl:px-16 m-2 text-xl text-white whitespace-nowrap font-medium transition-colors duration-150 bg-client-primary rounded-lg hover:bg-opacity-80; }
    .basic-form-field-listbox button {height:42px;}
    .action-button { @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-inverse hover:bg-opacity-70 focus:outline-none focus:ring-0 focus:ring-offset-0 items-center; }
    .text-danger { @apply text-red-500 }
    .text-warning { @apply text-yellow-500 }
    .text-success { @apply text-green-500 }

    /** Tables CSS **/
    .crmls-table-main-actions { @apply my-1 mb-2 relative flex flex-col justify-between px-1 space-x-0 sm:space-x-2 space-y-2 sm:space-y-0 sm:flex-row }
    .crmls-table-main-actions div { @apply flex flex-col items-center space-x-0 sm:space-x-2 space-y-2 sm:space-y-0 sm:flex-row }
    .crmls-table-main-container { @apply py-2 align-middle inline-block min-w-full }
    .crmls-table-wrapper { @apply shadow overflow-hidden rounded-lg }
    .crmls-table { @apply min-w-full divide-y divide-default border border-default }
    .crmls-table thead { @apply bg-header }
    .crmls-table th { @apply relative p-3 }
    .crmls-table th span { @apply block text-xs font-medium text-inverse uppercase tracking-wider }
    .crmls-table td { @apply p-3 text-sm text-primary text-center }
    .crmls-table td.important { @apply font-medium }
    .crmls-table td button { @apply whitespace-nowrap }
    .crmls-table tbody tr:nth-child(even) { @apply bg-secondary }
    .crmls-table tbody tr:nth-child(odd) { @apply bg-primary }

    /** Form CSS **/
    .crmls-submit-btn { @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-inverse bg-header hover:bg-opacity-50 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-blue-500 }
    .crmls-fields-wrap { @apply space-y-6 sm:space-y-5 col-span-12 }
    .crmls-field-wrap { @apply sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start pt-5 first:border-0 }
    .crmls-field-wrap label { @apply flex items-center text-sm font-medium text-primary sm:mt-px sm:pt-2 }
    .crmls-field-wrap.compact { @apply pt-0 }
    .crmls-tooltip-icon { @apply w-5 h-5 text-header inline cursor-pointer ml-1 focus:outline-none focus:ring-0 focus:ring-offset-0 }

    /** Tabs CSS **/
    .crmls-tabs {}
    .crmls-tab-list { @apply flex flex-col sm:flex-row }
    .crmls-tab { @apply text-header text-opacity-50 py-4 px-6 block focus:outline-none cursor-pointer relative }
    .crmls-tab.active { @apply text-header border-b-2 border-divider }
    .crmls-tab-content { @apply mt-6 sm:mt-5 space-y-6 sm:space-y-5 max-w-full px-2 }

    /** Training Calendar CSS **/
    .training-calendar .e-subject, .training-calendar .e-time { @apply text-shadow; }
    .training-calendar .e-agenda-view .e-subject, .training-calendar .e-agenda-view .e-time { @apply text-shadow-none; }
    .training-calendar .calendar-detail-icon { padding-right: unset !important; color: unset !important; display: unset !important; background-color: unset !important; border: none !important; }

    .custom-input__control { @apply bg-secondary border border-default !important }
    .custom-input__placeholder { @apply text-secondary !important; }
    .custom-input__indicator-separator { @apply border border-default !important; }
    .custom-input__indicator { @apply text-secondary !important; }
    .custom-input__menu { @apply bg-secondary text-secondary !important; }
    .custom-input__single-value, .custom-input__value-container { @apply text-secondary !important; }

    /** Syncfusion and browser overrides **/
    .e-input-group { @apply bg-secondary px-3 py-1 text-primary !important; }
    .e-input-group span { @apply text-secondary !important; }
    .dark input[type="date"]::-webkit-calendar-picker-indicator, .contrast input[type="date"]::-webkit-calendar-picker-indicator { filter: invert(1); } /* This will invert the calendar icon based on theme for date inputs */
    .e-grid .e-table { @apply bg-primary text-primary !important }
    .e-grid .e-rowcell:not(.e-editedbatchcell):not(.e-updatedtd), .e-grid .e-detailrowcollapse:not(.e-editedbatchcell):not(.e-updatedtd), .e-grid .e-detailrowexpand:not(.e-editedbatchcell):not(.e-updatedtd), .e-grid .e-gridcontent .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd), .e-grid .e-gridheader .e-rowdragdrop:not(.e-editedbatchcell):not(.e-updatedtd), .e-grid .e-emptyrow:not(.e-editedbatchcell):not(.e-updatedtd), .e-grid th.e-headercell[aria-sort='ascending'] .e-headertext, .e-grid th.e-headercell[aria-sort='descending'] .e-headertext, .e-fe-popup.e-dialog td { @apply text-primary !important }
    .e-grid .e-headercell, .e-grid .e-detailheadercell, .e-grid.e-gridhover tr[role='row']:not(.e-editedrow):hover .e-rowcell:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role='row']:hover .e-detailrowcollapse:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role='row']:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-rtl .e-gridhover tr[role='row']:hover .e-rowdragdrop:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid.e-gridhover tr[role='row']:hover .e-detailrowexpand:not(.e-cellselectionbackground):not(.e-active):not(.e-updatedtd):not(.e-indentcell), .e-grid td.e-active, .e-dlg-overlay { @apply bg-secondary text-secondary !important }
    .e-grid, .e-grid .e-rowcell, .e-grid .e-groupcaption, .e-grid .e-indentcell, .e-grid .e-recordplusexpand, .e-grid .e-recordpluscollapse, .e-grid .e-rowdragdropcell, .e-grid .e-detailrowcollapse, .e-grid .e-detailrowexpand, .e-grid .e-detailindentcell, .e-grid .e-detailcell, .e-grid .e-headercell, .e-grid .e-detailheadercell, .e-grid .e-gridheader { @apply border-divider bg-primary text-primary !important }
     .e-pager, .e-pager .e-pagercontainer, .e-pager .e-firstpage, .e-pager .e-prevpage, .e-pager .e-firstpagedisabled, .e-pager .e-prevpagedisabled, .e-pager .e-nextpage, .e-pager .e-lastpage, .e-pager .e-nextpagedisabled, .e-pager .e-lastpagedisabled, .e-pager .e-prevpagedisabled, .e-pager .e-prevpage, .e-pager .e-nextpage, .e-pager .e-nextpagedisabled, .e-pager .e-lastpagedisabled, .e-pager .e-lastpage, .e-pager .e-firstpage, .e-pager .e-firstpagedisabled, .e-pager .e-numericitem, .e-dlg-header-content, .e-dlg-content, .e-footer-content { @apply bg-primary text-primary border-default !important }
    .e-pager .e-currentitem, .e-pager .e-currentitem:hover, .e-toolbar { @apply bg-primary text-header !important }
    .e-filemanager .e-toolbar, .e-toolbar ,.e-toolbar-items, .e-splitter.e-splitter-horizontal, .e-splitter.e-splitter-vertical, .e-splitter.e-splitter-horizontal .e-pane, .e-splitter.e-splitter-vertical .e-pane, .e-grid .e-content, .e-filemanager, .e-filemanager .e-address, .e-filemanager .e-address .e-addressbar-ul .e-breadcrumb-menu .e-breadcrumb-submenu { @apply bg-primary text-primary border-default !important }
    .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn, .e-filemanager .e-treeview .e-list-item.e-active > .e-fullrow, .e-treeview .e-list-item.e-active > .e-fullrow { @apply bg-primary border-default !important }
    .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text, .e-toolbar .e-tbar-btn.e-btn .e-icons, .e-filemanager .e-address .e-search-wrap .e-input-group .e-input, .e-filemanager .e-address .e-address-list-item, .e-treeview .e-list-text, .e-treeview .e-list-item div.e-icons::before, .e-filemanager .e-address .e-address-list-item:last-child .e-list-text, .e-filemanager .e-address .e-icons, .e-search-wrap .e-input-group .e-input::placeholder, .e-dlg-header, .e-dlg-header *, .e-btn.e-flat, .e-css.e-btn.e-flat, .e-dialog .e-btn .e-btn-icon.e-icon-dlg-close { @apply text-primary !important }
    .e-footer-content .e-btn.e-primary.e-flat:not([DISABLED]) { @apply bg-header text-inverse !important }
    .e-pager .e-icon-prev::before, .e-grid-menu .e-icon-prev::before { content: '\e654'; }

    /** TinyMCE overrides **/
    .tox-tinymce, .tox:not(.tox-tinymce-inline) .tox-editor-header, .tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary, .tox .tox-tbtn, .tox .tox-tbtn:hover, .tox .tox-statusbar, .tox .tox-menubar, .tox .tox-mbtn { @apply bg-secondary text-secondary border-default !important; }
    .tox .tox-toolbar-overlord, .tox .tox-collection--list .tox-collection__item--active:not(.tox-collection__item--state-disabled), .tox .tox-menu { @apply bg-primary text-primary !important; }
    .tox .tox-collection__item-label, .tox .tox-collection__item-icon { @apply text-primary !important; }
    .tox .tox-collection--list .tox-collection__item--active, .tox .tox-collection--list .tox-collection__item--enabled { @apply bg-header text-white !important; }
    .tox .tox-collection--list .tox-collection__item--enabled .tox-collection__item-label { @apply text-white !important; }
    .tox .tox-tbtn { @apply text-secondary !important; }
    .tox .tox-tbtn svg { @apply fill-current !important; }

    /** Default article styles (if no inline styles are added via the wysiwyg editor when creating an article, these will be defaults for common tags) **/
    .article-details h1 { @apply mb-4 text-5xl font-extrabold text-header; }
    .article-details h2 { @apply mb-3 text-4xl font-bold text-header; }
    .article-details h3 { @apply mb-2 text-3xl font-bold text-header; }
    .article-details h4 { @apply mb-1 text-2xl font-semibold text-header; }
    .article-details h5 { @apply text-xl font-semibold text-header; }
    .article-details h6 { @apply text-lg font-medium text-header; }

    .article-details p { @apply mb-3; }
    .article-details a { @apply text-tertiary hover:text-opacity-80 hover:underline; }
    .article-details ul { @apply mb-2 space-y-1 max-w-md list-disc list-inside; }
    .article-details ol { @apply mb-2 pl-5 mt-2 space-y-1 list-decimal list-inside; }

    /** Password field strength indicator **/
    .password-strength-meter::before { content: ""; height: 4px; display: block; transition: width 0.2s; }
    .password-strength-meter[data-color = "gray"] { @apply bg-secondary; }
    .password-strength-meter[data-color = "red"] { background-color: red; }
    .password-strength-meter[data-color = "orange"] { background-color: orange; }
    .password-strength-meter[data-color = "#03a2cc"] { background-color: #03a2cc; }
    .password-strength-meter[data-color = "#10d652"] { background-color: #10d652; }

    /** React Slider **/
    .slide-pane__header { @apply bg-secondary !important; }
    .slide-pane__title { @apply text-secondary; }
    .slide-pane__close { @apply text-secondary; }
    .slide-pane__content { @apply bg-primary text-primary; }


    /** Public pages css **/
    .public-dashboard-wrapper { @apply flex flex-wrap items-center justify-between mt-8 3xl:mt-16; }
    /* .public-dashboard-wrapper { height: calc(100vh - 90px); } */

    sub, sup { font-size: 50%; }
}

@layer utilities {
    @variants responsive {
        .text-shadow { text-shadow: 1px 1px 2px rgb(0, 0, 0, .25); }
        .text-shadow-md { text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25); }
        .text-shadow-lg { text-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25); }
        .text-shadow-none { text-shadow: none; }
    } 
}

.fade-anim { animation: fadeAnim 0.3s ease-in-out; }
@keyframes fadeAnim { from { opacity: 0; } to { opacity: 1; } }
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar { display: none; }
.react-horizontal-scrolling-menu--scroll-container { -ms-overflow-style: none; /* IE and Edge */ scrollbar-width: none; /* Firefox */ }
.__react_component_tooltip::before { z-index: -1 !important; }

/* marketplace apps badge css */
.app-card-marketplace:after, .app-card-aor:after, .app-card-broker:after, .app-card-new:after {
    height: 0;
    border-style: solid;
    border-width: 0 0 25px 25px;
    right: 0;
    bottom: 0;
    position: absolute;
    padding-right: 10px;
    color: white;
    opacity: .25;
}
.app-card-marketplace:after { content: 'm'; border-color: transparent transparent #a1a1aa transparent; }
.app-card-aor:after { content: 'a'; border-color: transparent transparent #aadbaa transparent; }
.app-card-broker:after { content: 'b'; border-color: transparent transparent #da9fb2 transparent; }
.app-card-new:after { content: 'New!'; font-size: .5rem; line-height: 1rem; border-width: 0 0 15px 18px; border-color: transparent transparent #d32323 transparent; opacity: 1; }
.react-horizontal-scrolling-menu--inner-wrapper{ position: absolute; width: 100%; }
.box-shadow-lg { box-shadow: -30px 30px var(--bg-header) }

/* Support Icon Flyout Menu */
.support-menu { transition: all 0.25s ease-in-out; transition-delay: 0.75s; -webkit-transform: rotate(0deg); transform: rotate(0deg); right: 2%; z-index: 99999; -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }  
.support-menu li { transition: all 0.25s ease-in-out; transition-delay: 0.5s; width: 49.4px; height: 49.4px; margin: -29.7px 0 0 -29.7px; opacity: 0; border-radius: 50%; list-style-type: none;  position: fixed; z-index: 100; left: 50%; top: 50%; display: flex; justify-content: center; align-items: center; }
.support-menu li li:nth-of-type(1) { -webkit-transform: rotate(180deg) translate(0, 0); transform: rotate(180deg) translate(0, 0); animation-name: crazy; animation-duration: 2s; animation-delay: 1s; animation-iteration-count: infinite; }
.support-menu li li:nth-of-type(2) { -webkit-transform: rotate(0deg) translate(0, 0); transform: rotate(0deg) translate(0, 0); }
.support-menu li li:nth-of-type(3) { -webkit-transform: rotate(0deg) translate(0, 0); transform: rotate(0deg) translate(0, 0); }
.support-menu:hover li { transition-delay: 0.1s; opacity: 1; }
.support-menu:hover li:nth-of-type(1) { -webkit-transform: rotate(229deg) translate(0, 90px); transform: rotate(229deg) translate(0, 90px); }
.support-menu:hover li:nth-of-type(2) { -webkit-transform: rotate(180deg) translate(0, 90px); transform: rotate(180deg) translate(0, 90px); }
.support-menu:hover li:nth-of-type(3) { -webkit-transform: rotate(130deg) translate(0, 90px); transform: rotate(130deg) translate(0, 90px); }
.support-menu li:nth-of-type(1) svg { transform: rotate(120deg); }
.support-menu li:nth-of-type(2) svg { transform: rotate(180deg); }
.support-menu li:nth-of-type(3) svg { transform: rotate(140deg); }

.insight-search-input{ width:100%; border-radius: 6px; }
.insight-search-input-loader{ position: absolute; bottom: 11px; right: 16px; }
.dark .insight-search-input, .contrast .insight-search-input{ background-color:#17202a; color:white; }
.dark .insight-search-input-loader span span, .contrast .insight-search-input-loader span span { background-color:white; }

#print-view { display:none; }
/* print styles*/
@media print {
    #print-view { display:block; }
    div:not(#print-view) { display:none; }
}

.light {
    --bg-header: 79 152 166; /* blue for header/button */
    --bg-primary: 255 255 255; /* white for main content */
    --bg-secondary: 243 244 246; /* gray for header/sidebar */
    --bg-tertiary: 229 231 235; /* darker gray for standoff border/sidebar color */
    --bg-menu: 239 239 239; /* main menu bg */
    --bg-menucollapse: 79 152 166; /* main menu callapsed color */

    --text-header: 79 152 166; /* blue for header/button */
    --text-primary: 92 100 117; /* gray to stand out from primary bg */
    --text-secondary: 107 114 128; /* darker gray to stand out from secondary bg */
    --text-tertiary: 230 82 57; /* link or standout color */
    --text-inverse: 255 255 255; /* reverse text on header color */
    --text-menucollapse: 255 255 255; /* menu collapsed text/icon color */

    --border-divider: 229 231 235; /* link or standout color */
    --border-default: 243 244 246; /* gray for header/sidebar */
    --border-dark: 196 202 206; /* dark border (menu border) */
    --border-header: 79 152 166; /* blue for header/button */
    --border-menu: 220 239 247; /* menu right border when expanded */
}

.dark {
    --bg-header: 79 152 166;
    --bg-primary: 28 39 51;
    --bg-secondary: 23 32 42;
    --bg-tertiary: 24 25 26;
    --bg-menu: 23 32 42;
    --bg-menucollapse: 24 25 26;

    --text-header: 79 152 166;
    --text-primary: 107 114 128;
    --text-secondary: 92 100 117;
    --text-tertiary: 230 82 57;
    --text-inverse: 255 255 255;
    --text-menucollapse: 92 100 117;

    --border-divider: 24 25 26;
    --border-default: 23 32 42;
    --border-dark: 24 25 26;
    --border-header: 79 152 166;
    --border-menu: 24 25 26;
}

.solar {
    --bg-header: 230 82 57;
    --bg-primary: 239 230 198;
    --bg-secondary: 206 193 150;
    --bg-tertiary: 155 143 102;
    --bg-menu: 206 193 150;
    --bg-menucollapse: 155 143 102;

    --text-header: 79 152 166;
    --text-primary: 155 143 102;
    --text-secondary: 178 164 117;
    --text-tertiary: 230 82 57;
    --text-inverse: 255 255 255;
    --text-menucollapse: 178 164 117;

    --border-divider: 155 143 102;
    --border-default: 206 193 150;
    --border-dark: 155 143 102;
    --border-header: 79 152 166;
    --border-menu: 155 143 102;
}

.contrast {  
    --bg-header: 26 235 265; 
    --bg-primary: 0 0 0;
    --bg-secondary: 20 20 20;
    --bg-tertiary: 15 14 14;
    --bg-menu: 20 20 20;
    --bg-menucollapse: 15 14 14;

    --text-header: 26 235 265;
    --text-primary: 255 255 255;
    --text-secondary: 255 255 255;
    --text-tertiary: 255 255 0;
    --text-inverse: 0 0 0;
    --text-menucollapse: 255 255 255;

    --border-divider: 26 235 265;
    --border-default: 20 20 20;
    --border-dark: 26 235 265;
    --border-header: 26 235 265;
    --border-menu: 15 14 14;
}

.custom-input__input, .basic-form-field-typeahead input:focus {
    border: none !important;
    outline: none !important;
    --tw-ring-color: none;
}

.dark .custom-input__control, .dark .custom-input__menu, .contrast .custom-input__control, .contrast .custom-input__menu { 
    border-width: 0 !important;
}

.dark .custom-input__control, .dark .custom-input__menu { 
    background-color: #17202a !important;
}

.contrast .custom-input__control, .contrast .custom-input__menu { 
    background-color:#141414 !important;
}

.dark .custom-input__menu { 
    color:white !important;
}
.contrast .basic-form-field-typeahead input {
    color: white !important;
}
.contrast .custom-input__placeholder{
    opacity: .5 !important;
}

.dark .basic-form-field-typeahead input {
    color: rgb(var(--text-primary) / var(--tw-text-opacity))  !important;
}

.contrast .custom-input__option--is-focused, .dark .custom-input__option--is-focused{
    background-color: #333333 !important;
}

.basic-form-field-typeahead .input-registered {
    padding-left: 0;
    padding-right: 0;
    height: 42px;
    padding-top: 0;
}

.carousel .thumb img {
    width: 100% !important;
    height: 100% !important;
}

.carousel .slide img {
    width: auto;
    height:200px;
}
